//  Warraty relates servics

import { SYSTEM_ERROR } from "../config/CONSTANTS";
import axios from "axios";
import {
  CLAIM_MASTER_URL,
  CLAIM_ORDER_MASTER_URL,
  CLAIM_VALUE_MASTER_URL,
  EVALUATION_PARTS_MASTER_URL,
  RELATED_HOURS_EXPENSES_MASTER_URL,
  RELATED_LABOUR_DETAILS_MASTER_URL,
  RELATED_PARTS_MASTER_URL,
  WARRANTY_CLAIM_PAYER_MASTER_URL,
  Warranty_Component_GetById_GET,
  WARRANTY_EVALUATION_MASTER_URL,
} from "./CONSTANTS";
import Cookies from "js-cookie";

/* ----------------- Authorization ------------------- */

// get the Authorization AuthToken data
export function getAccessToken() {
  let storedCookie = Cookies.get("logincustomerinfo");
  console.log("storedcookie", JSON.parse(storedCookie)?.access_token);
  let getCookiesJsonData;
  if (storedCookie != undefined) {
    getCookiesJsonData = JSON.parse(storedCookie);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${getCookiesJsonData?.access_token}`,
    },
  };
  return config;
}

/* ------------------------------------------------------------ */

//Fetch Work List
export const getWarratyComponentData = (id) => {
  console.log("warrantyServices > getWarratyComponentData called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(`${Warranty_Component_GetById_GET}/${id}`, getAccessToken())
        .then((res) => {
          console.log("getWarratyComponentData > axios res=", res);
          resolve(res);
        })
        .catch((err) => {
          console.log("getWarratyComponentData > axios err=", err);
          reject("Error in getWarratyComponentData axios!");
        });
    } catch (error) {
      console.error("in warrantyServices > getWarratyComponentData, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

//Add Quote payer
export const addWarrantyClaimPayer = (quoteId, payerData) => {
  console.log("warrantyServices > addPayerData called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(WARRANTY_CLAIM_PAYER_MASTER_URL, payerData, getAccessToken())
        .then((res) => {
          console.log("addPayerData > axios res=", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            console.log("Status:", res.status);
            reject("Error in addPayerData axios!");
          }
        })
        .catch((err) => {
          console.log("addQuoteItem axios err :", err);
          reject("Error in addPayerData axios!");
        });
    } catch (error) {
      console.error("Genreal Exception addPayerData : ", error);
      reject(SYSTEM_ERROR);
    }
  });
};

//Update Quote payer
export const updateWarrantyClaimPayerData = (quotePayerId, payerData) => {
  console.log("warrantyServices > updatePayerData called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(`${WARRANTY_CLAIM_PAYER_MASTER_URL}/${quotePayerId}`, payerData, getAccessToken())
        .then((res) => {
          console.log("updatePayerData > axios res=", res);
          if (res.status === 200) {
            resolve(res);
          } else {
            console.log("Status:", res.status);
            reject("Error in updatePayerData axios!");
          }
        })
        .catch((err) => {
          console.log("addQuoteItem axios err :", err);
          reject("Error in addPayerData axios!");
        });
    } catch (error) {
      console.error("Genreal Exception addPayerData : ", error);
      reject(SYSTEM_ERROR);
    }
  });
};

//Remove payer
export const removeWarrantyClaimPayer = (payerId) => {
  console.log("service warrantyServices > removePayer called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .delete(`${WARRANTY_CLAIM_PAYER_MASTER_URL}/${payerId}`, getAccessToken())
        .then((res) => {
          console.log("warrantyServices -> removePayer response: ", res);
          if (res.status === 200) {
            resolve("Successfully removed the payer!");
          } else {
            reject(res.error);
          }
        })
        .catch((err) => {
          console.log("removePayer > axios err=", err);
          reject("Error in removePayer axios!");
        });
    } catch (error) {
      console.error("removePayer general exception", error);
      reject(SYSTEM_ERROR);
    }
  });
};

// Create Claim
export const createClaim = (reqObj) => {
  console.log("warrantyServices > createClaim called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(CLAIM_MASTER_URL, reqObj, getAccessToken())
        .then((res) => {
          console.log("createClaim > axios res=", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Error in createClaim axios!");
          }
        })
        .catch((err) => {
          console.log("createClaim axios err :", err);
          reject("Error in createClaim axios!");
        });
    } catch (error) {
      console.error("Genreal Exception createClaim : ", error);
      reject(SYSTEM_ERROR);
    }
  });
};

// Update claim
export const updateClaim = (claimId, reqObj) => {
  console.log("warrantyServices > updateClaim called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(`${CLAIM_MASTER_URL}/${claimId}`, reqObj, getAccessToken())
        .then((res) => {
          console.log("updateClaim > axios res=", res);
          if (res.status === 200) {
            resolve(res);
          } else {
            console.log("Status:", res.status);
            reject("Error in updateClaim axios!");
          }
        })
        .catch((err) => {
          console.log("addQuoteItem axios err :", err);
          reject("Error in updateClaim axios!");
        });
    } catch (error) {
      console.error("Genreal Exception updateClaim : ", error);
      reject(SYSTEM_ERROR);
    }
  });
};

// Create Claim Order
export const createClaimOrder = (reqObj) => {
  console.log("warrantyServices > createClaimOrder called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(CLAIM_ORDER_MASTER_URL, reqObj, getAccessToken())
        .then((res) => {
          console.log("createClaimOrder > axios res=", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Error in createClaimOrder axios!");
          }
        })
        .catch((err) => {
          console.log("createClaimOrder axios err :", err);
          reject("Error in createClaimOrder axios!");
        });
    } catch (error) {
      console.error("Genreal Exception createClaimOrder : ", error);
      reject(SYSTEM_ERROR);
    }
  });
};

// update Claim Order
export const updateClaimOrder = (claimOrderId, reqObj) => {
  console.log("warrantyServices > updateClaimOrder called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(`${CLAIM_ORDER_MASTER_URL}/${claimOrderId}`, reqObj, getAccessToken())
        .then((res) => {
          console.log("updateClaimOrder > axios res=", res);
          if (res.status === 200) {
            resolve(res);
          } else {
            console.log("Status:", res.status);
            reject("Error in updateClaimOrder axios!");
          }
        })
        .catch((err) => {
          console.log("addQuoteItem axios err :", err);
          reject("Error in updateClaimOrder axios!");
        });
    } catch (error) {
      console.error("Genreal Exception updateClaimOrder : ", error);
      reject(SYSTEM_ERROR);
    }
  });
};

// create evaluation
export const createClaimEvaluation = (reqObj) => {
  console.log("warrantyServices > createClaimEvaluation called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(WARRANTY_EVALUATION_MASTER_URL, reqObj, getAccessToken())
        .then((res) => {
          console.log("createClaimEvaluation > axios res=", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Error in createClaimEvaluation axios!");
          }
        })
        .catch((err) => {
          console.log("createClaimEvaluation axios err :", err);
          reject("Error in createClaimEvaluation axios!");
        });
    } catch (error) {
      console.error("Genreal Exception createClaimEvaluation : ", error);
      reject(SYSTEM_ERROR);
    }
  });
};

// update evaluation
export const updateClaimEvaluation = (id, reqObj) => {
  console.log("warrantyServices > updateClaimEvaluation called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(`${WARRANTY_EVALUATION_MASTER_URL}/${id}`, reqObj, getAccessToken())
        .then((res) => {
          console.log("updateClaimEvaluation > axios res=", res);
          if (res.status === 200) {
            resolve(res);
          } else {
            console.log("Status:", res.status);
            reject("Error in updateClaimEvaluation axios!");
          }
        })
        .catch((err) => {
          console.log("addQuoteItem axios err :", err);
          reject("Error in updateClaimEvaluation axios!");
        });
    } catch (error) {
      console.error("Genreal Exception updateClaimEvaluation : ", error);
      reject(SYSTEM_ERROR);
    }
  });
};

// create evaluation parts
export const createClaimEvaluationPart = (reqObj) => {
  console.log("warrantyServices > createClaimEvaluationPart called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(EVALUATION_PARTS_MASTER_URL, reqObj, getAccessToken())
        .then((res) => {
          console.log("createClaimEvaluationPart > axios res=", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Error in createClaimEvaluationPart axios!");
          }
        })
        .catch((err) => {
          console.log("createClaimEvaluationPart axios err :", err);
          reject("Error in createClaimEvaluationPart axios!");
        });
    } catch (error) {
      console.error("Genreal Exception createClaimEvaluationPart : ", error);
      reject(SYSTEM_ERROR);
    }
  });
};

// update evaluation parts
export const updateClaimEvaluationParts = (id, reqObj) => {
  console.log("warrantyServices > updateClaimEvaluationParts called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(`${EVALUATION_PARTS_MASTER_URL}/${id}`, reqObj, getAccessToken())
        .then((res) => {
          console.log("updateClaimEvaluationParts > axios res=", res);
          if (res.status === 200) {
            resolve(res);
          } else {
            console.log("Status:", res.status);
            reject("Error in updateClaimEvaluationParts axios!");
          }
        })
        .catch((err) => {
          console.log("addQuoteItem axios err :", err);
          reject("Error in updateClaimEvaluationParts axios!");
        });
    } catch (error) {
      console.error("Genreal Exception updateClaimEvaluationParts : ", error);
      reject(SYSTEM_ERROR);
    }
  });
};

// create realetd hours and expenses
export const createRelatedHRandExpenses = (reqObj) => {
  console.log("warrantyServices > createRelatedHRandExpenses called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(RELATED_HOURS_EXPENSES_MASTER_URL, reqObj, getAccessToken())
        .then((res) => {
          console.log("createRelatedHRandExpenses > axios res=", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Error in createRelatedHRandExpenses axios!");
          }
        })
        .catch((err) => {
          console.log("createRelatedHRandExpenses axios err :", err);
          reject("Error in createRelatedHRandExpenses axios!");
        });
    } catch (error) {
      console.error("Genreal Exception createRelatedHRandExpenses : ", error);
      reject(SYSTEM_ERROR);
    }
  });
};

// update realetd hours and expenses
export const updateRelatedHRandExpenses = (id, reqObj) => {
  console.log("warrantyServices > updateRelatedHRandExpenses called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(`${RELATED_HOURS_EXPENSES_MASTER_URL}/${id}`, reqObj, getAccessToken())
        .then((res) => {
          console.log("updateRelatedHRandExpenses > axios res=", res);
          if (res.status === 200) {
            resolve(res);
          } else {
            console.log("Status:", res.status);
            reject("Error in updateRelatedHRandExpenses axios!");
          }
        })
        .catch((err) => {
          console.log("addQuoteItem axios err :", err);
          reject("Error in updateRelatedHRandExpenses axios!");
        });
    } catch (error) {
      console.error("Genreal Exception updateRelatedHRandExpenses : ", error);
      reject(SYSTEM_ERROR);
    }
  });
};

// create related parts
export const createRelatedParts = (reqObj) => {
  console.log("warrantyServices > createRelatedParts called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(RELATED_PARTS_MASTER_URL, reqObj, getAccessToken())
        .then((res) => {
          console.log("createRelatedParts > axios res=", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Error in createRelatedParts axios!");
          }
        })
        .catch((err) => {
          console.log("createRelatedParts axios err :", err);
          reject("Error in createRelatedParts axios!");
        });
    } catch (error) {
      console.error("Genreal Exception createRelatedParts : ", error);
      reject(SYSTEM_ERROR);
    }
  });
};

// update related parts
export const updateRelatedParts = (id, reqObj) => {
  console.log("warrantyServices > updateRelatedParts called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(`${RELATED_PARTS_MASTER_URL}/${id}`, reqObj, getAccessToken())
        .then((res) => {
          console.log("updateRelatedParts > axios res=", res);
          if (res.status === 200) {
            resolve(res);
          } else {
            console.log("Status:", res.status);
            reject("Error in updateRelatedParts axios!");
          }
        })
        .catch((err) => {
          console.log("addQuoteItem axios err :", err);
          reject("Error in updateRelatedParts axios!");
        });
    } catch (error) {
      console.error("Genreal Exception updateRelatedParts : ", error);
      reject(SYSTEM_ERROR);
    }
  });
};

// create claim value
export const createClaimValue = (reqObj) => {
  console.log("warrantyServices > createClaimValue called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(CLAIM_VALUE_MASTER_URL, reqObj, getAccessToken())
        .then((res) => {
          console.log("createClaimValue > axios res=", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Error in createClaimValue axios!");
          }
        })
        .catch((err) => {
          console.log("createClaimValue axios err :", err);
          reject("Error in createClaimValue axios!");
        });
    } catch (error) {
      console.error("Genreal Exception createClaimValue : ", error);
      reject(SYSTEM_ERROR);
    }
  });
};

// update claim value
export const updateClaimValue = (id, reqObj) => {
  console.log("warrantyServices > updateClaimValue called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(`${CLAIM_VALUE_MASTER_URL}/${id}`, reqObj, getAccessToken())
        .then((res) => {
          console.log("updateClaimValue > axios res=", res);
          if (res.status === 200) {
            resolve(res);
          } else {
            console.log("Status:", res.status);
            reject("Error in updateClaimValue axios!");
          }
        })
        .catch((err) => {
          console.log("addQuoteItem axios err :", err);
          reject("Error in updateClaimValue axios!");
        });
    } catch (error) {
      console.error("Genreal Exception updateClaimValue : ", error);
      reject(SYSTEM_ERROR);
    }
  });
};

// create claim value
export const createRelatedLabour = (reqObj) => {
  console.log("warrantyServices > createRelatedLabour called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(RELATED_LABOUR_DETAILS_MASTER_URL, reqObj, getAccessToken())
        .then((res) => {
          console.log("createRelatedLabour > axios res=", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Error in createRelatedLabour axios!");
          }
        })
        .catch((err) => {
          console.log("createRelatedLabour axios err :", err);
          reject("Error in createRelatedLabour axios!");
        });
    } catch (error) {
      console.error("Genreal Exception createRelatedLabour : ", error);
      reject(SYSTEM_ERROR);
    }
  });
};

// update claim value
export const updateRelatedLabour = (id, reqObj) => {
  console.log("warrantyServices > updateRelatedLabour called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(`${RELATED_LABOUR_DETAILS_MASTER_URL}/${id}`, reqObj, getAccessToken())
        .then((res) => {
          console.log("updateRelatedLabour > axios res=", res);
          if (res.status === 200) {
            resolve(res);
          } else {
            console.log("Status:", res.status);
            reject("Error in updateRelatedLabour axios!");
          }
        })
        .catch((err) => {
          console.log("addQuoteItem axios err :", err);
          reject("Error in updateRelatedLabour axios!");
        });
    } catch (error) {
      console.error("Genreal Exception updateRelatedLabour : ", error);
      reject(SYSTEM_ERROR);
    }
  });
};

// create Generative AI
export const createGenerativeAI = (rObj) => {
  console.log("warrantyServices > createGenerativeAI called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(
          `https://b617qxh7la.execute-api.us-east-2.amazonaws.com/dev/`,
          JSON.stringify(rObj),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
          //   {
          //   headers: headersData,
          // }
        )
        .then((res) => {
          console.log("createGenerativeAI > axios res=", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            console.log("Status:", res.status);
            reject("Error in createGenerativeAI axios!");
          }
        })
        .catch((err) => {
          console.log("addQuoteItem axios err :", err);
          reject("Error in createGenerativeAI axios!");
        });
    } catch (error) {
      console.error("Genreal Exception createGenerativeAI : ", error);
      reject(SYSTEM_ERROR);
    }
  });
};
