import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Autocomplete, Divider, Grid, Stack, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { Fragment, useContext, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import searchIcon from "../assets/icons/svg/search.svg";
import CarouselTile from "../components/CarouselTile";
import GuidedSolutionModal from "../components/GuidedSolutionModal";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import SupportOutlinedIcon from "@mui/icons-material/SupportOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { fetchCoverageSuggestions, getPortfolioSearch, getPortfolios } from "../services/portfolioServices";
import CustomizedSnackbar from "../shared/CustomSnackBar";
import PortfolioConfigModal from "./PortfolioConfigModal";
import PortfolioSearchResult from "./PortfolioSearchResult";
import { CATEGORIES, OWL_CAROUSEL_OPTIONS } from "./CONSTANTS";
import SearchHome from "./SearchComp";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import EquipmentEnrollModal from "./warrantyManagement/equipment/EquipmentEnrollModal";
import { equipmentMasterComp, partsSearch } from "../navigation/navigationConst";
import GenerateAISupportModal from "./GenerateAISupportModal";
import WarrantyClaimSummaryModal from "./WarrantyClaimSummaryModal";
import RegisterProductModal from "./RegisterProductModal";
import { SOLUTION_PORTFOLIO_SEARCH_URL } from "services/CONSTANTS";
import { callGetApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";

export default function HomeScreen() {
  const { auth } = useContext(AuthContext);
  const [show, setShow] = React.useState(false);

  // Snack Bar State
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };
  const navigate = useNavigate();

  useEffect(() => {
    fetchPortfolios();
  }, []);

  const [recommendedProds, setRecommendedProds] = useState([]);
  const [loadingProds, setLoadingProds] = useState(true);

  const fetchPortfolios = async () => {
    const portfolioIds = [192, 193, 194, 195, 196, 200, 201, 222, 223, 225, 228, 229, 231];
    const rUrl = SOLUTION_PORTFOLIO_SEARCH_URL + portfolioIds.map((id) => `portfolioId:${id}`).join(" OR ");
    callGetApi(
      null,
      rUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setRecommendedProds(responseData);
          setLoadingProds(false);
        } else {
          setRecommendedProds([]);
          setLoadingProds(false);
        }
      },
      (error) => {
        setRecommendedProds([]);
        setLoadingProds(false);
      }
    );
    // await getPortfolios()
    //   .then((res) => {
    //     setRecommendedProds(res.data);
    //     setLoadingProds(false);
    //   })
    //   .catch((e) => {
    //     setLoadingProds(false);
    //   });
  };

  return (
    <>
      {loadingProds ? (
        "Loading"
      ) : (
        <>
          <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
          <div className="content-body" style={{ minHeight: "884px" }}>
            {/* Recommended part */}
            <div className="container-fluid">
              {/* <div className="d-flex align-items-center mt-2">
              <h4 className="font-weight-600 mb-0">Recommended</h4>
            </div> */}
              {/* <Typography className="row justify-content-end pt-2">
                <Typography className="text-primary border p-2">
                  {auth &&
                    `${auth?.customerId ? `${auth?.customerId} - ` : ""}${auth?.customerName ? auth?.customerName : ""
                    }`}
                </Typography>
              </Typography> */}
              <br />
              <QuickIcons handleSnack={handleSnack} />
              {/* <UsageCategory handleSnack={handleSnack} /> */}
              <RecommendedCarousel recommendedProducts={recommendedProds} />
              <AccesoryProducts handleSnack={handleSnack} />
              <Services />
            </div>
          </div>
        </>
      )}
    </>
  );
}

// Recommended products starts
function RecommendedCarousel({ recommendedProducts }) {
  return (
    <div className="card p-4 mt-5" style={{ background: "#D0E1EF" }}>
      <h5 className="font-weight-600 mb-0">Recommended Products</h5>

      <div className="contain-slider mt-3">
        <OwlCarousel items={4} className="owl-theme" style={{ zIndex: "0" }} loop nav {...OWL_CAROUSEL_OPTIONS}>
          {recommendedProducts.map((recoproduct) => (
            <CarouselTile key={recoproduct.slno} product={recoproduct} />
          ))}
        </OwlCarousel>
      </div>
    </div>
  );
}

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

// Recommended products starts
function QuickIcons({ handleSnack }) {
  const [searchShow, setSearchShow] = useState(false);
  const [openEquEnrollModal, setOpenEquEnrollModal] = useState(false);
  const [equWithDummyData, setEquWithDummyData] = useState(false);

  const [openAISearchModal, setOpenAISearchModal] = useState(false);
  const [fileUploadForProduct, setFileUploadForProduct] = useState(false);

  const [openAISupportModal, setOpenAISupportModal] = useState(false);
  const [openWarrantyClaimSummaryModal, setOpenWarrantyClaimSummaryModal] = useState(false);
  const [openFileUploadModal, setOpenFileUploadModal] = useState(false);

  const [aiRecordObj, setAiRecordObj] = useState(null);
  const [aiPartsRecord, setAiPartsRecord] = useState([]);

  const [equRecordId, setEquRecordId] = useState(null);

  const navigate = useNavigate();
  const redirectEquipment = () => {
    navigate("/equipment");
  };

  const redirectEquipmentSearch = () => {
    navigate(equipmentMasterComp);
  };

  const redirectPartsSearch = () => {
    navigate(partsSearch);
  };

  const handleOpenEquCreateModal = () => {
    setOpenAISearchModal(false);
    setFileUploadForProduct(false);
    setOpenEquEnrollModal(true);
    setEquWithDummyData(false);
    setEquRecordId(null);
  };

  // show Equipment create modal from More Options Popup << File Upload
  const handleShowEquCreateModal = () => {
    setOpenAISearchModal(false);
    setFileUploadForProduct(false);
    setOpenEquEnrollModal(true);
    setEquWithDummyData(true);
    setEquRecordId(null);
  };

  // open Warranty Claim summary Modal from Generate AI Modal
  const handleOpenAIWarrantySummaryModal = () => {
    setOpenAISupportModal(false);
    setOpenWarrantyClaimSummaryModal(true);
  };

  // file upload modal open close
  const handleOpenCloseFileUploadModal = () => {
    setOpenWarrantyClaimSummaryModal(!openWarrantyClaimSummaryModal);
    setOpenFileUploadModal(!openFileUploadModal);
  };

  // open register product modal
  const handleOpenRegisterProductModal = () => {
    setOpenAISearchModal(true);
    setEquWithDummyData(true);
  };

  // file upload modal open close
  const handleOpenProductFileUploadModal = () => {
    setOpenAISearchModal(false);
    setFileUploadForProduct(true);
  };

  // file upload modal open close
  const handleCloseProductFileUploadModal = () => {
    setOpenAISearchModal(true);
    setFileUploadForProduct(false);
  };

  return (
    <>
      <div className="card p-4 mt-2 border">
        <Grid container>
          <Grid
            item
            xs={2}
            className="card p-4 m-2 border justify-content-center text-primary cursor-pointer"
            // // onClick={redirectEquipment}
            onClick={handleOpenEquCreateModal}
            // onClick={handleOpenRegisterProductModal}
          >
            <AddBoxOutlinedIcon style={{ fontSize: 40 }} />
          </Grid>
          <Grid
            item
            xs={2}
            className="card p-4 m-2 border justify-content-center text-primary cursor-pointer"
            // onClick={redirectEquipment}
            onClick={redirectEquipmentSearch}
          >
            <SupportOutlinedIcon style={{ fontSize: 40 }} />
          </Grid>
          <Grid
            item
            xs={2}
            className="card p-4 m-2 border justify-content-center text-primary cursor-pointer"
            onClick={() => {
              setOpenAISupportModal(true);
              setAiPartsRecord([]);
              setAiRecordObj(null);
            }}
            // onClick={redirectPartsSearch}
          >
            <ConstructionOutlinedIcon style={{ fontSize: 40 }} />
          </Grid>
          <Grid item xs={2} className="card p-4 m-2 border justify-content-center text-primary cursor-pointer" onClick={() => setSearchShow(true)}>
            <SearchOutlinedIcon style={{ fontSize: 40 }} />
          </Grid>
          <Grid item xs={2} className="card p-4 m-2 border justify-content-center text-primary" onClick={handleOpenRegisterProductModal}>
            <MoreVertOutlinedIcon style={{ fontSize: 40 }} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={2} className="pl-1 m-2 justify-content-center">
            Register a product
          </Grid>
          <Grid item xs={2} className="pl-1 m-2 justify-content-center">
            Support equipment
            {/* Support my equipment */}
          </Grid>
          <Grid item xs={2} className="pl-1 m-2 justify-content-center">
            {/* Order parts for equipment */}
            AI Support
            {/* Order parts for my equipment */}
          </Grid>
          <Grid item xs={2} className="pl-1 m-2 justify-content-center">
            Search service plan
            {/* Buy or extend a service plan */}
          </Grid>
          <Grid item xs={2} className="pl-1 m-2 justify-content-center">
            More options
          </Grid>
        </Grid>
        <SearchHome show={searchShow} handleClose={() => setSearchShow(false)} />
      </div>
      {openEquEnrollModal && (
        <EquipmentEnrollModal
          show={openEquEnrollModal}
          hideModal={() => setOpenEquEnrollModal(false)}
          handleSnack={handleSnack}
          formHomeScreen={equWithDummyData}
          equRecordId={equRecordId}
          setEquRecordId={setEquRecordId}
          // recordId={equRecordId}
          // equipmentRecord={equipmentData}
        />
      )}

      {openAISupportModal && (
        <GenerateAISupportModal
          show={openAISupportModal}
          hideModal={() => setOpenAISupportModal(false)}
          handleSnack={handleSnack}
          handleOpenAIWarrantySummaryModal={handleOpenAIWarrantySummaryModal}
          setAiRecordObj={setAiRecordObj}
          setAiPartsRecord={setAiPartsRecord}
        />
      )}

      {(openWarrantyClaimSummaryModal || openFileUploadModal) && (
        <WarrantyClaimSummaryModal
          show={openWarrantyClaimSummaryModal}
          hideModal={() => setOpenWarrantyClaimSummaryModal(false)}
          handleSnack={handleSnack}
          openFileUploadModal={openFileUploadModal}
          handleOpenCloseFileUploadModal={handleOpenCloseFileUploadModal}
          recordData={aiRecordObj}
          partsRecord={aiPartsRecord}
        />
      )}

      {(openAISearchModal || fileUploadForProduct) && (
        <RegisterProductModal
          show={openAISearchModal}
          hideModal={() => setOpenAISearchModal(false)}
          handleSnack={handleSnack}
          fileUploadForProduct={fileUploadForProduct}
          handleOpenProductFileUploadModal={handleOpenProductFileUploadModal}
          handleCloseProductFileUploadModal={handleCloseProductFileUploadModal}
          handleShowEquCreateModal={handleShowEquCreateModal}
        />
      )}
    </>
  );
}
// recomended products components ends

// usage category starts
function UsageCategory(props) {
  const [category, setCategory] = React.useState("");
  const [modelSuggestions, setModelSuggestions] = useState([]);
  const [model, setModel] = useState("");
  const [result, setResult] = useState([]);
  const [selectedPortfolio, setSelectedPortfolio] = useState({});
  function handleFilterChange(event, newValue) {
    if (event) findSuggestions(event.target.name, newValue);
  }
  const findSuggestions = async (searchCategory, searchText) => {
    const searchCriteriaList = [
      {
        filterKey: "modelNo",
        value: searchText,
        operation: "cn",
      },
    ];

    let searchCriteria = {
      searchCriteriaList: searchCriteriaList,
      dataOption: "all",
      listItem: "modelNo",
    };
    if (searchText && searchCategory) {
      fetchCoverageSuggestions(searchCriteria).then((results) => {
        setModelSuggestions(results.data.map((indModel) => indModel.value));
      });
    }
  };
  async function handleChangeModel(value) {
    setResult([]);
    setCategory("");
    setModel(value);
    setOpen(false);
    let searchPayload = {
      searchCriteriaList: [
        {
          filterKey: "modelNo",
          value: value,
          operation: "eq",
        },
      ],
      dataOption: "all",
    };
    if (value) {
      await getPortfolioSearch(searchPayload)
        .then((data) => {
          if (data.data?.length === 0) {
            props.handleSnack("info", "No matching result found!");
          } else {
            setResult(data.data);
          }
        })
        .catch((err) => {
          props.handleSnack("error", "Error occurred while searching for portfolios!");
        });
    }
  }
  const handleCategory = async (event) => {
    let { value } = event.target;
    setCategory(value);

    setResult([]);
    let searchPayload = {
      searchCriteriaList: [
        {
          filterKey: "usageCategory",
          value: value,
          operation: "eq",
        },
      ],
      dataOption: "all",
    };

    await getPortfolioSearch(searchPayload)
      .then((data) => {
        if (data.data?.length === 0) {
          // handleSnack("info", "No matching result found!")
        } else {
          setResult(data.data);
        }
      })
      .catch((err) => {
        // handleSnack("error", "Error occurred while searching for portfolios!")
      });
  };
  const [open, setOpen] = React.useState(false);
  const [showDetailConfig, setShowDetailConfig] = React.useState(false);

  const handleSelectPortfolio = (portfolio) => {
    setSelectedPortfolio(portfolio);
    setShowDetailConfig(true);
  };
  return (
    <>
      <PortfolioConfigModal
        show={showDetailConfig}
        handleClose={() => setShowDetailConfig(false)}
        setSelectedPortfolio={setSelectedPortfolio}
        selectedPortfolio={selectedPortfolio}
        handleSnack={props.handleSnack}
      />
      <div className="card p-4 mt-5" style={{ background: "#D0E1EF" }}>
        <div className="row">
          <div className="col-md-8">
            <h5 className="font-weight-600 mb-0">Select the solutions you want to search</h5>
          </div>
          <div className="col-md-4 d-flex justify-content-end">
            <a href="/CommerceGuided" className="btn bg-primary text-white py-2">
              Guided Solution
            </a>
          </div>
        </div>
        <div className="card mt-4 p-4">
          <div>
            <Autocomplete
              options={modelSuggestions}
              // loading={loading}
              open={open}
              onInputChange={(event, newInputValue) => {
                if (newInputValue.length === 0) {
                  if (open) setOpen(false);
                } else {
                  handleFilterChange(event, newInputValue);
                  if (!open) setOpen(true);
                }
              }}
              value={model}
              onChange={(event, values) => handleChangeModel(values)}
              noOptionsText={<span style={{ fontSize: 12 }}>No Options</span>}
              size="small"
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{
                    ...props.style,
                    fontSize: 12,
                    borderBottom: "1px solid #00000025",
                  }}
                >
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                  <div class="input-group icons border-radius-10 border overflow-hidden my-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-transparent border-0 pr-0 " id="basic-addon1">
                        <img src={searchIcon} />
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="Model #"
                      {...params.inputProps}
                      // style={{ width: "100%", borderRadius: 5, borderColor: '#00000030', marginBlock: 10, padding: 6 }}
                      name="modelNo"
                      class="form-control search-form-control"
                    />
                  </div>
                </div>
              )}
            />
          </div>
          <Divider variant="middle" sx={{ width: 200, mx: "auto", my: 2, fontWeight: 500 }}>
            OR
          </Divider>
          <div className="row">
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={category}
                onChange={handleCategory}
              >
                {CATEGORIES.map((usageCategory) => (
                  <div className="col-md-3 my-3">
                    <FormControlLabel
                      value={usageCategory.value}
                      disabled={model && result}
                      control={<Radio />}
                      label={
                        <div className="px-2">
                          <Typography variant="body2" fontWeight={"500"} className="text-primary">
                            {usageCategory.label}
                          </Typography>
                        </div>
                      }
                    />
                    <Typography fontSize={14} marginX={4}>
                      {usageCategory.description}
                    </Typography>
                  </div>
                ))}
              </RadioGroup>
            </FormControl>
          </div>
          {result && result.length > 0 ? (
            <Fragment>
              <Divider sx={{ my: 2 }} />
              <div className="row">
                <div className="col-md-7">
                  <PortfolioSearchResult result={result} setSelectedPortfolio={handleSelectPortfolio} />
                </div>
                <div className="col-md-5">
                  Search the portfolio through guided search
                  <a href="/CommerceGuided" className="btn bg-primary text-white py-2 mx-3">
                    Guided Solution
                  </a>
                  <Divider sx={{ mx: "auto", mt: 4, mb: 2, fontWeight: 500 }} />
                  <Typography sx={{ my: 2 }}>Related Portfolios</Typography>
                  <Stack spacing={{ xs: 1, sm: 2 }}>
                    <Typography fontSize={13} color="black">
                      Sample_Portfolio_1 <OpenInNewIcon />
                    </Typography>
                    <Typography fontSize={13} color="black">
                      Sample_Portfolio_2 <OpenInNewIcon />
                    </Typography>
                    <Typography fontSize={13} color="black">
                      Sample_Portfolio_3 <OpenInNewIcon />
                    </Typography>
                  </Stack>
                </div>
              </div>
            </Fragment>
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* <GuidedSolutionModal
        searchSolutionSelected={searchSolutionSelected}
        searchPortfolioOpen={searchPortfolioOpen}
        handleSearchPortfolioClose={handleSearchPortfolioClose}
      /> */}
    </>
  );
}

// Accessory products starts

function AccesoryProducts(props) {
  const accessoryProducts = [
    {
      slno: 1,
      id: "CYH73508",
      price: 36000,
      description: "This service contract covers the service of the core parts of the machine.",
      imgSource: "./assets/images/tool1.png",
    },
    {
      slno: 2,
      id: "CYH3508",
      price: 28000,
      description: "This service contract covers the service of the core parts of the machine.",
      imgSource: "./assets/images/tool2.png",
    },
    {
      slno: 3,
      id: "CYH3508",
      price: 22000,
      description: "This service contract covers the service of the core parts of the machine.",
      imgSource: "./assets/images/tool3.png",
    },
    {
      slno: 4,
      id: "2471437",
      price: 64790,
      description: "This service contract covers the service of the core parts of the machine.",
      imgSource: "./assets/images/tool4.png",
    },
    {
      slno: 5,
      id: "PS7890",
      price: 20000,
      description: "This service contract covers the service of the core parts of the machine.",
      imgSource: "./assets/images/tool2.png",
    },
  ];

  return (
    <div className="card p-4 mt-5" style={{ background: "#D0E1EF" }}>
      <h5 className="font-weight-600 mb-0">Components</h5>
      <div className="contain-slider my-3">
        <OwlCarousel className="owl-theme" loop {...OWL_CAROUSEL_OPTIONS}>
          {accessoryProducts.map((product) => (
            <AccessoryProductsTile key={product.slno} product={product} handleSnack={props.handleSnack} />
          ))}
        </OwlCarousel>
      </div>
    </div>
  );
}

function AccessoryProductsTile({ product, handleSnack }) {
  return (
    <>
      <div className="item3 border" style={{ minHeight: 250 }}>
        <div className="p-1">
          <h4 className="text-light mt-3">{product.id}</h4>
          <p className="mb-0 mt-2">{product.description}</p>
        </div>
        <Divider sx={{ mt: 4 }} />
        <Stack direction="row" spacing={3} justifyContent="space-between" alignItems="center">
          <h4 className="text-red mt-3">
            <b>{formatter.format(product.price)}</b>
          </h4>
          <div className="text-right">
            <button className="service-button">
              <AddShoppingCartIcon
                className="font-size-30"
                size="large"
                onClick={() => handleSnack("success", `${product.id}` + " product has been added to the cart")}
              ></AddShoppingCartIcon>
            </button>
          </div>
        </Stack>
      </div>
    </>
  );
}

function Services() {
  return (
    <div className="card p-4 mt-5" style={{ background: "#D0E1EF" }}>
      {/* <div className="p-4 mt-4"> */}
      <h5 className="font-weight-600 mb-0">Recommended Services</h5>
      {/* <p className="mb-0">
          <b>
            Amet minim molit non deserunt ullamco est sit alique dolor do amet
            sint. Velit officia consequat duis enim velit molit. Exercitation
          </b>
        </p> */}
      {/* </div> */}
      <div className="contain-slider my-4">
        <OwlCarousel className="owl-theme" loop nav {...OWL_CAROUSEL_OPTIONS}>
          <div className="item border" style={{ height: "70%" }}>
            <Typography fontSize={16} variant={"h6"}>
              Repair Services
            </Typography>
            <ul className="mt-3" style={{ paddingLeft: "20px" }}>
              <li className="mt-3" style={{ listStyle: "disc", fontSize: 13 }}>
                Cover for all models of the fleet starting from the base model
              </li>
              <li className="mt-3" style={{ listStyle: "disc" }}>
                Periodic maintenance triggered every 3 months
              </li>
            </ul>
            <Divider />
            <Stack direction="row" spacing={3} justifyContent="space-between" alignItems="center">
              <h4 className="text-red mt-3">
                <b>$20,000</b>
              </h4>
              <div className="text-right">
                <button className="service-button">
                  <AddShoppingCartIcon className="font-size-30" size="large"></AddShoppingCartIcon>
                </button>
              </div>
            </Stack>
          </div>
          <div className="item border" style={{ height: "70%" }}>
            <Typography fontSize={16} variant={"h6"}>
              CV agreement
            </Typography>
            <ul className="mt-3" style={{ paddingLeft: "20px" }}>
              <li className="mt-3" style={{ listStyle: "disc", fontSize: 13 }}>
                Cover for all models of the fleet starting from the base model
              </li>
              <li className="mt-3" style={{ listStyle: "disc", fontSize: 13 }}>
                Periodic maintenance triggered every 3 months
              </li>
            </ul>
            <Divider />
            <Stack direction="row" spacing={3} justifyContent="space-between" alignItems="center">
              <h4 className="text-red mt-3">
                <b>$20,000</b>
              </h4>
              <div className="text-right">
                <button className="service-button">
                  <AddShoppingCartIcon className="font-size-30" size="large"></AddShoppingCartIcon>
                </button>
              </div>
            </Stack>
          </div>
          <div className="item border" style={{ height: "70%" }}>
            <Typography fontSize={16} variant={"h6"}>
              Maintenance Service
            </Typography>
            <ul className="mt-3" style={{ paddingLeft: "20px" }}>
              <li className="mt-3" style={{ listStyle: "disc", fontSize: 13 }}>
                Cover for all models of the fleet starting from the base model
              </li>
              <li className="mt-3" style={{ listStyle: "disc", fontSize: 13 }}>
                Periodic maintenance triggered every 3 months
              </li>
            </ul>
            <Divider />
            <Stack direction="row" spacing={3} justifyContent="space-between" alignItems="center">
              <h4 className="text-red mt-3">
                <b>$20,000</b>
              </h4>
              <div className="text-right">
                <button className="service-button">
                  <AddShoppingCartIcon className="font-size-30" size="large"></AddShoppingCartIcon>
                </button>
              </div>
            </Stack>
          </div>
          <div className="item border" style={{ height: "70%" }}>
            <Typography fontSize={16} variant={"h6"}>
              Repair Service
            </Typography>

            <ul className="mt-3" style={{ paddingLeft: "20px" }}>
              <li className="mt-3" style={{ listStyle: "disc", fontSize: 13 }}>
                Cover for all models of the fleet starting from the base model
              </li>
              <li className="mt-3" style={{ listStyle: "disc", fontSize: 13 }}>
                Periodic maintenance triggered every 3 months
              </li>
            </ul>
            <Divider />
            <Stack direction="row" spacing={3} justifyContent="space-between" alignItems="center">
              <h4 className="text-red mt-3">
                <b>$20,000</b>
              </h4>
              <div className="text-right">
                <button className="service-button">
                  <AddShoppingCartIcon className="font-size-30" size="large"></AddShoppingCartIcon>
                </button>
              </div>
            </Stack>
          </div>
          <div className="item border" style={{ height: "260px" }}>
            <Typography fontSize={16} variant={"h6"}>
              Repair Services
            </Typography>

            <ul className="mt-3" style={{ paddingLeft: "20px" }}>
              <li className="mt-3" style={{ listStyle: "disc", fontSize: 13 }}>
                Cover for all models of the fleet starting from the base model
              </li>
              <li className="mt-3" style={{ listStyle: "disc", fontSize: 13 }}>
                Periodic maintenance triggered every 3 months
              </li>
            </ul>
            <Divider />
            <Stack direction="row" spacing={3} justifyContent="space-between" alignItems="center">
              <h4 className="text-red mt-3">
                <b>$20,000</b>
              </h4>
              <div className="text-right">
                <button className="service-button">
                  <AddShoppingCartIcon className="font-size-30" size="large"></AddShoppingCartIcon>
                </button>
              </div>
            </Stack>
          </div>
        </OwlCarousel>
      </div>
    </div>
  );
}
